import { startOfDay } from "date-fns";
import { formatInTimeZone, fromZonedTime } from "date-fns-tz";

export const startOfDayAtTz = (d: Date, tz: string): Date =>
    fromZonedTime(startOfDay(d), tz);

export const startOfTodayAtTz = (tz: string): Date =>
    startOfDayAtTz(new Date(), tz);

export const tzKL = "Asia/Kuala_Lumpur";

export const fmtDt = (dt: string | Date, fmt: string, tz: string): string =>
    formatInTimeZone(dt, tz, fmt);

export const fmtDtKL = (dt: string | Date, fmt: string): string =>
    fmtDt(dt, fmt, tzKL);
