import { FormEvent, ReactNode, useMemo, useState } from "react";
import Link from "next/link";
import { cx } from "class-variance-authority";
import {
    Controller,
    FieldErrors,
    useForm,
    UseFormClearErrors,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Combobox } from "@headlessui/react";
import { Chip } from "components/Chip";
import { Button } from "components/Button";
import { ScrollArea, ScrollAreaCombobox } from "components/ScrollArea";
import { DatePicker } from "components/CalendarNew";
import { MobileHeader } from "components/MobileHeader/v2";
import { CategoryList } from "components/CategoryList";
import {
    DialogSheet,
    DialogHeader,
    DialogContent,
    DialogDescription,
    DialogTrigger,
} from "components/DialogSheet";
import {
    CalendarUnfilled,
    CheckUnfilled,
    CloseUnfilled,
    LocationUnfilled,
    OfficeBuildingUnfilled,
} from "components/Icons";
import { Separator } from "components/Separator";
import { DropdownSelect } from "components/DropdownSelect";
import { FragmentType, getFragment, graphql } from "lib/gql";
import { format, isEqual } from "date-fns";
import { byStateImportance } from "utils/index";
import { constructDateRange, dateInputOptions, DateTimeFormValue } from ".";
import { toDate } from "date-fns-tz";
import postcodesToState from "assets/lookups/states";
import { Input } from "components/Forms";
import { fmtDtKL } from "lib/date-fns-util";

export const categoriesSearchMobileFragment = graphql(`
    fragment SearchMobileCategories on Category {
        uid
        name
        ...CategoryListCategories
    }
`);

export const locationsSearchMobileFragment = graphql(`
    fragment SearchMobileLocations on SearchLocation {
        uid
        name
        city
        coordinates {
            latitude
            longitude
        }
    }
`);

export const venuesSearchMobileFragment = graphql(`
    fragment SearchMobileVenues on Organisation {
        uid
        name
        ...CategoryListVenues
    }
`);

type TFunc = TFunction<["components/SearchPage", "common"]>;
type KeyProp = string | number | undefined;
type ComboboxInputProps =
    | {
          id: string | number;
          text?: string;
          link?: string;
      }
    | null
    | undefined;

type ComboboxOptionsProps = {
    id: string | number;
    text?: string;
    link?: string;
};
type DefaultLocationOptions = {
    state: string;
    options: ComboboxOptionsProps[];
};

export const CategoryChip = ({
    categoriesFragment,
    venuesFragment,
    value,
    onChange,
}: {
    categoriesFragment?: FragmentType<typeof categoriesSearchMobileFragment>[];
    venuesFragment?: FragmentType<typeof venuesSearchMobileFragment>[];
    value?: string | null;
    onChange: (v: string | null) => void;
}): JSX.Element => {
    const { t } = useTranslation(["components/SearchPage", "common"]);
    const [open, setOpen] = useState(false);
    const categories =
        getFragment(categoriesSearchMobileFragment, categoriesFragment) ?? [];
    const venues =
        getFragment(venuesSearchMobileFragment, venuesFragment) ?? [];
    const current = categories.find((c) => c.uid === value);

    const onClear = (e): void => {
        e.preventDefault();
        e.stopPropagation();
        onChange(null);
    };

    return (
        <DialogSheet open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Chip
                    className="flex gap-2"
                    variant={current ? "selected" : "default"}
                >
                    <span className="text-nowrap">
                        {current?.name ??
                            t("subtitleCategory", "Select a sport")}
                    </span>
                    <CloseUnfilled
                        className={cx("size-4", !current && "hidden")}
                        onClick={onClear}
                    />
                </Chip>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader showSeparator={false}>
                    {t("subtitleCategory", "Select a sport")}
                </DialogHeader>
                <DialogDescription className="flex">
                    <ScrollArea className="flex flex-1 pr-2">
                        <CategoryList
                            categoriesFragment={categories}
                            venuesFragment={venues}
                            type="input"
                            value={value}
                            onChange={(v) => {
                                setOpen(false);
                                onChange(v ?? null);
                            }}
                        />
                    </ScrollArea>
                </DialogDescription>
            </DialogContent>
        </DialogSheet>
    );
};

export const MobileHeaderWithLocation = ({
    locationsFragment,
    venuesFragement,
    value,
    onChange,
}: {
    locationsFragment?: FragmentType<typeof locationsSearchMobileFragment>[];
    venuesFragement?: FragmentType<typeof venuesSearchMobileFragment>[];
    value: string | null;
    onChange: (v: string | null) => void;
}): JSX.Element => {
    const { t } = useTranslation("components/SearchPage");

    const groupedLocationsByState = useMemo(() => {
        const locations =
            getFragment(locationsSearchMobileFragment, locationsFragment) ?? [];
        const groupedLocations = {};
        locations.forEach((l) => {
            let stateKey = l.city ?? "UNKNOWN";
            if (!l.city) {
                const matchedState = postcodesToState.find((state) =>
                    state.city.some((c) => c.name === l.name),
                );
                stateKey = matchedState?.name ?? "UNKNOWN";
            }

            if (!groupedLocations[stateKey]) groupedLocations[stateKey] = [];
            groupedLocations[stateKey]?.push(l);
        });

        return Object.keys(groupedLocations)
            .map((state) => {
                return {
                    state: state,
                    locations: groupedLocations[state],
                };
            })
            .filter((l) => l.state !== "UNKNOWN");
    }, [locationsFragment]);

    const venues =
        getFragment(venuesSearchMobileFragment, venuesFragement) ?? [];
    const venueOptions: ComboboxOptionsProps[] = venues
        .map((v) => ({
            id: v.uid,
            text: v.name,
            link: `/centre/${v.name}/${v.uid}`,
        }))
        .sort((a, b) =>
            a.text.toLowerCase().localeCompare(b.text.toLowerCase()),
        );
    const locationOptions: ComboboxOptionsProps[] = groupedLocationsByState
        .sort(byStateImportance)
        .flatMap((l) =>
            l.locations.map((c) => ({
                id: c.uid,
                text: `${c.name}, ${l.state}`,
            })),
        );
    const defaultLocationOptions: DefaultLocationOptions[] =
        groupedLocationsByState
            .map((l) => ({
                state: l.state,
                options: l.locations.map((c) => ({
                    id: c.uid,
                    text: c.name,
                })),
            }))
            .sort(byStateImportance);

    return (
        <MobileHeader
            className="!grid-cols-[20px_1fr_0px] !shadow-none"
            left={{ className: "mt-1.5", icon: "back", href: "/search" }}
        >
            <LocationComboboxInput
                icon={<LocationUnfilled className="text-blue-grey-400" />}
                placeholder={t(
                    "subtitleLocation",
                    "Search venue name, city, or state",
                )}
                locationOptions={locationOptions}
                venueOptions={venueOptions}
                defaultLocationOptions={defaultLocationOptions}
                value={value}
                handleChange={(v) => onChange(v ?? null)}
                t={t}
            />
        </MobileHeader>
    );
};

type DateTimeChipFormValue = { dtrange: DateTimeFormValue };
type DateTimeChipProps = {
    values?: DateTimeFormValue;
    onSubmit: (v: DateTimeFormValue) => void;
    disabled?: boolean;
};
type StartEndFormProps = {
    values?: DateTimeFormValue;
    onOpenChange: (v: boolean) => void;
    onSubmit: (v: DateTimeFormValue) => void;
    t: TFunc;
};

export const DateTimeChip = ({
    values,
    onSubmit,
    disabled,
}: DateTimeChipProps): JSX.Element => {
    const { t } = useTranslation(["components/SearchPage", "common"]);
    const [open, setOpen] = useState(false);

    let text: string | undefined;
    const { startDt, endDt } = constructDateRange(values);
    if (startDt) {
        let startDate = fmtDtKL(startDt, "dd MMM yyyy");
        let endDate: string | undefined;
        text = startDate;
        if (endDt && !isEqual(startDt, endDt)) {
            startDate = fmtDtKL(startDt, "dd MMM yyyy, hh:mma");
            endDate = fmtDtKL(endDt, " - hh:mma");
            text = startDate + endDate;
        }
    }

    const onClear = (e): void => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit({
            date: null,
            time: null,
            meridiem: null,
            duration: null,
        });
    };

    return (
        <DialogSheet open={open} onOpenChange={setOpen}>
            <DialogTrigger disabled={disabled} asChild>
                <Chip
                    className={cx(
                        "flex gap-2",
                        disabled && "pointer-events-none",
                    )}
                    variant={text ? "selected" : "default"}
                >
                    <span className="text-nowrap">
                        {text ?? t("subtitleDate", "Pick a date")}
                    </span>
                    <CloseUnfilled
                        className={cx("size-4", !text && "hidden")}
                        onClick={onClear}
                    />
                </Chip>
            </DialogTrigger>
            <DialogContent>
                <StartEndForm
                    values={values}
                    onOpenChange={setOpen}
                    onSubmit={onSubmit}
                    t={t}
                />
            </DialogContent>
        </DialogSheet>
    );
};

const StartEndForm = ({
    values,
    onOpenChange,
    onSubmit,
    t,
}: StartEndFormProps): JSX.Element => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        clearErrors,
        setError,
        resetField,
    } = useForm<DateTimeChipFormValue>({
        defaultValues: { dtrange: values },
    });

    const handleSubmitWithoutPropagation = handleSubmit(
        (v, e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (!v.dtrange.date) return;
            if (v.dtrange.date && !v.dtrange.time)
                setError("dtrange.time", { message: "Required" });
            if (v.dtrange.date && !v.dtrange.meridiem)
                setError("dtrange.meridiem", { message: "Required" });
            if (v.dtrange.date && !v.dtrange.duration)
                setError("dtrange.duration", { message: "Required" });
            if (
                v.dtrange.date &&
                v.dtrange.time &&
                v.dtrange.meridiem &&
                v.dtrange.duration
            ) {
                onSubmit(v.dtrange);
                onOpenChange(false);
            }
        },
    );

    const errorDt = errors.dtrange;

    const onClear = (e): void => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit({
            date: null,
            time: null,
            meridiem: null,
            duration: null,
        });
        resetField("dtrange", {
            defaultValue: {
                date: null,
                time: null,
                meridiem: null,
                duration: null,
            },
        });
    };

    return (
        <form
            className="flex h-full flex-col"
            onSubmit={handleSubmitWithoutPropagation}
        >
            <DialogHeader showSeparator={false}>
                {t("selectDateTime", "Select date & time")}
            </DialogHeader>
            <DialogDescription className="flex flex-col justify-between">
                <div className="mb-2 mt-4 flex flex-1 flex-col gap-3 overflow-y-auto">
                    <Controller
                        control={control}
                        name="dtrange"
                        render={({ field: { onChange, value } }) => (
                            <StartEndInput
                                value={value}
                                onChange={onChange}
                                errors={errorDt}
                                clearErrors={clearErrors}
                                t={t}
                            />
                        )}
                    />
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        variant="outlined"
                        size="lg"
                        onClick={onClear}
                        type="button"
                        className="w-full"
                    >
                        {t("common:clear", "Clear")}
                    </Button>
                    <Button type="submit" size="lg" className="w-full">
                        {t("common:apply", "Apply")}
                    </Button>
                </div>
            </DialogDescription>
        </form>
    );
};

type StartEndInputProps = {
    value?: DateTimeFormValue;
    onChange: (v: DateTimeFormValue) => void;
    errors?: FieldErrors<DateTimeFormValue>;
    clearErrors: UseFormClearErrors<DateTimeChipFormValue>;
    t: TFunc;
};

const StartEndInput = ({
    value,
    onChange,
    errors,
    clearErrors,
    t,
}: StartEndInputProps): JSX.Element => {
    const { date, time, meridiem, duration } = value ?? {
        date: null,
        time: null,
        meridiem: null,
        duration: null,
    };

    const handleDateChange = (v: DateTimeFormValue): void => {
        const { timeOptions, meridiemOptions } = dateInputOptions(t, v.date);

        const defaultValueTime =
            timeOptions.length === 1 ? timeOptions[0]?.value : undefined;
        const defaultValueMeridiem =
            meridiemOptions.length === 1
                ? meridiemOptions[0]?.value
                : undefined;

        onChange({
            ...v,
            time: defaultValueTime ?? v.time,
            meridiem: defaultValueMeridiem ?? v.meridiem,
        });
    };

    const { timeOptions, meridiemOptions, durationOptions } = dateInputOptions(
        t,
        date,
        time,
    );

    const selectedDate = date ? toDate(date) : undefined;
    return (
        <div className="flex flex-1 flex-col gap-3">
            <div className="typography-h6 font-bold text-blue-grey-900">
                {t("common:date", "Date")}
            </div>
            <DatePicker
                selected={selectedDate}
                onSelect={(e) => {
                    if (e !== selectedDate) {
                        const formattedDate =
                            (e && format(e, "yyyy-MM-dd")) ?? null;
                        handleDateChange({
                            date: formattedDate,
                            time: null,
                            meridiem: null,
                            duration: null,
                        });
                        clearErrors(["dtrange"]);
                    }
                }}
                disabled={{ before: new Date() }}
            >
                <Input
                    key={selectedDate?.toISOString()}
                    placeholder={t("common:date", "Date")}
                    defaultValue={
                        selectedDate &&
                        format(selectedDate, "dd MMM yyyy, eeee")
                    }
                    suffix={
                        <div className="flex items-center justify-center">
                            <CalendarUnfilled className="size-4 text-blue-grey-200" />
                        </div>
                    }
                />
            </DatePicker>
            <div className="typography-h6 font-bold text-blue-grey-900">
                {t("timeDuration", "Start time & duration")}
            </div>
            <div className="flex gap-1">
                <div className="flex w-28 flex-col gap-1">
                    <DropdownSelect
                        key={time}
                        disabled={!date && !time}
                        value={time ?? undefined}
                        onValueChange={(e) => {
                            onChange({ ...value, time: e });
                            clearErrors(["dtrange"]);
                        }}
                        options={timeOptions}
                        placeholder="00:00"
                    />
                    <ErrorDiv message={errors?.time?.message} />
                </div>
                <div className="flex w-20 flex-col gap-1">
                    <DropdownSelect
                        key={meridiem}
                        disabled={!time && !meridiem}
                        value={meridiem ?? undefined}
                        onValueChange={(e) => {
                            onChange({ ...value, meridiem: e });
                            clearErrors(["dtrange"]);
                        }}
                        options={meridiemOptions}
                        placeholder={t("common:AM")}
                    />
                    <ErrorDiv message={errors?.meridiem?.message} />
                </div>
                <div className="flex grow flex-col gap-1">
                    <DropdownSelect
                        key={duration}
                        disabled={!meridiem}
                        value={duration ?? undefined}
                        onValueChange={(e) => {
                            onChange({ ...value, duration: e });
                            clearErrors(["dtrange"]);
                        }}
                        options={durationOptions}
                        placeholder={t("common:hour", "{{count}} Hour", {
                            count: 1,
                        })}
                    />
                    <ErrorDiv message={errors?.duration?.message} />
                </div>
            </div>
        </div>
    );
};

const LocationComboboxInput = ({
    icon,
    placeholder,
    locationOptions,
    venueOptions,
    defaultLocationOptions,
    disabled = false,
    value,
    handleChange,
    t,
}: {
    icon?: ReactNode;
    placeholder: string;
    locationOptions: ComboboxOptionsProps[];
    venueOptions: ComboboxOptionsProps[];
    defaultLocationOptions: DefaultLocationOptions[];
    disabled?: boolean;
    value?: string | null;
    handleChange: (v?: string) => void;
    t: TFunc;
}): JSX.Element => {
    const currentLocation = {
        id: "current_location",
        text: t("common:useCurrentLocation", "Use current location"),
    };
    const combineOptions = [
        currentLocation,
        ...venueOptions,
        ...locationOptions,
    ];
    let initialValue: ComboboxInputProps = null;
    initialValue = combineOptions.find((o) => o.id === value);
    const [selected, setSelected] = useState<KeyProp>(
        initialValue?.id ?? undefined,
    );
    const [query, setQuery] = useState("");
    const comboValue = selected ?? query;

    if (initialValue?.id !== selected) setSelected(initialValue?.id);

    const filteredOptions: ComboboxOptionsProps[] =
        query === ""
            ? combineOptions
            : combineOptions.filter((o) =>
                  o.text
                      ?.toLowerCase()
                      .replace(/\s+/g, "")
                      .replace(/[^\w\s]|_/g, "")
                      .includes(
                          query
                              .toLowerCase()
                              .replace(/\s+/g, "")
                              .replace(/[^\w\s]|_/g, ""),
                      ),
              );

    const filteredOptionsId = filteredOptions.map((o) => o.id);
    const onClear = (): void => {
        setQuery("");
        setSelected(undefined);
        handleChange("");
    };

    const onChange = (v: KeyProp): void => {
        setQuery(""); // reset query everytime an option is selected
        if (v?.toString().trim().length === 0) {
            setSelected(undefined);
            handleChange("");
            return;
        }
        setSelected(v);
        v && handleChange(v.toString());
    };
    let triggerColor = "text-blue-grey-400 placeholder:text-blue-grey-400";
    if (disabled)
        triggerColor = "text-blue-grey-300 placeholder:text-blue-grey-300";
    else if (comboValue)
        triggerColor = "text-blue-grey-900 placeholder:text-blue-grey-900";

    let child = (
        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
            {t("common:nothingFound", "Nothing found.")}
        </div>
    );

    const filteredVenueOptions = venueOptions.filter((o) =>
        filteredOptionsId.includes(o.id),
    );
    const filteredLocationOptions = locationOptions.filter((o) =>
        filteredOptionsId.includes(o.id),
    );

    if (filteredOptions.length !== 0 && comboValue) {
        child = (
            <>
                <PermanentOptions {...currentLocation} />
                <div className="typography-h5 cursor-default py-2 pl-3 font-semibold text-blue-grey-900 group-data-[location='0']:hidden">
                    {t("common:location", "Location")}
                </div>
                {filteredLocationOptions.map((o) => (
                    <ComboboxOption key={o.id} o={o} type="location" />
                ))}
                <div className="cursor-default px-3 py-2 group-data-[venue='0']:hidden">
                    <Separator orientation="horizontal" color="dark" />
                </div>
                <div className="typography-h5 cursor-default py-2 pl-3 font-semibold text-blue-grey-900 group-data-[venue='0']:hidden">
                    {t("common:venues", "Venues")}
                </div>
                {filteredVenueOptions.map((o) => (
                    <ComboboxOption key={o.id} o={o} type="venue" />
                ))}
            </>
        );
    } else if (!comboValue) {
        child = (
            <>
                <PermanentOptions {...currentLocation} />
                {defaultLocationOptions.map((s) => (
                    <div key={s.state}>
                        <span className="typography-h5 cursor-default py-2 pl-3 font-semibold text-blue-grey-900">
                            {s.state}
                        </span>
                        {s.options.map((o) => (
                            <ComboboxOption key={o.id} o={o} type="location" />
                        ))}
                    </div>
                ))}
            </>
        );
    }

    return (
        <Combobox
            value={comboValue}
            onChange={onChange}
            disabled={disabled}
            nullable
        >
            <div className="relative w-full">
                <div className="flex h-10 items-center gap-3 rounded-lg border border-solid border-blue-grey-50 px-3 py-2">
                    {icon}
                    <Combobox.Button className="flex w-full items-center border-none bg-transparent p-0 outline-none">
                        {({ open }) => (
                            <>
                                <Combobox.Input
                                    autoComplete="off"
                                    className={cx(
                                        "typography-main w-full border-none p-0 outline-none",
                                        triggerColor,
                                        disabled && "bg-white",
                                    )}
                                    placeholder={placeholder}
                                    displayValue={(id: KeyProp) =>
                                        combineOptions.find(
                                            (opt) => opt.id == id,
                                        )?.text ?? query
                                    }
                                    onChange={(event) => {
                                        setQuery(event.target.value);
                                    }}
                                    onClick={(e) => {
                                        if (open) e.stopPropagation();
                                    }}
                                />
                            </>
                        )}
                    </Combobox.Button>
                    {comboValue && (
                        <button
                            type="button"
                            onClick={() => onClear()}
                            className="m-0 flex cursor-pointer items-center justify-center border-none bg-transparent p-0"
                        >
                            <CloseUnfilled className="size-4" />
                        </button>
                    )}
                </div>
                <Combobox.Options
                    data-location={filteredLocationOptions.length}
                    data-venue={filteredVenueOptions.length}
                    className="typgoraphy-sub group absolute z-10 flex w-full overflow-auto rounded-b-lg bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                    <ScrollAreaCombobox className="max-h-60 max-w-full flex-1">
                        {child}
                    </ScrollAreaCombobox>
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

const PermanentOptions = (currentLocation: {
    id: string;
    text: string;
}): JSX.Element => (
    <>
        <Combobox.Option
            value=""
            className={({ active }) => cx(active && "bg-primary")}
        >
            <span className="block h-0 w-full" />
        </Combobox.Option>
        <Combobox.Option
            className={({ active }) =>
                cx(
                    "typography-sub relative flex cursor-default select-none pl-3 pr-4 text-primary-600",
                    active && "bg-primary-100",
                )
            }
            value={currentLocation.id}
        >
            {({ selected }) => (
                <>
                    <div className="py-2">{currentLocation.text}</div>
                    {selected && (
                        <span
                            className={cx(
                                "absolute inset-y-0 right-0 flex items-center pr-4 text-blue-grey-600",
                            )}
                        >
                            <CheckUnfilled
                                className="size-4"
                                aria-hidden="true"
                            />
                        </span>
                    )}
                </>
            )}
        </Combobox.Option>
    </>
);

const ComboboxOption = ({
    o,
    type,
}: {
    o: ComboboxOptionsProps;
    type: "venue" | "location";
}): JSX.Element => (
    <Combobox.Option
        key={o.id}
        className={({ active }) =>
            cx(
                "typography-sub relative flex cursor-default select-none pl-10 pr-4 text-blue-grey-900",
                active && "bg-primary-100",
            )
        }
        value={o.id}
    >
        {({ selected }) => (
            <>
                <span
                    className={cx(
                        "absolute inset-y-0 left-0 flex items-center pl-3 text-blue-grey-300",
                    )}
                >
                    {type === "venue" ? (
                        <OfficeBuildingUnfilled
                            className="size-4"
                            aria-hidden="true"
                        />
                    ) : (
                        <LocationUnfilled
                            className="size-4"
                            aria-hidden="true"
                        />
                    )}
                </span>
                <TextLink text={o.text ?? ""} link={o.link} />
                {selected && (
                    <span
                        className={cx(
                            "absolute inset-y-0 right-0 flex items-center pr-4 text-blue-grey-600",
                        )}
                    >
                        <CheckUnfilled className="size-4" aria-hidden="true" />
                    </span>
                )}
            </>
        )}
    </Combobox.Option>
);

const TextLink = ({
    text,
    link,
}: {
    text: string;
    link?: string;
}): JSX.Element => {
    const child = <span className="block flex-1 truncate py-2">{text}</span>;
    if (link) return <Link href={link}>{child}</Link>;
    return child;
};

const ErrorDiv = ({ message }: { message?: string }): JSX.Element => {
    if (!message) return <div />;
    return (
        <div className="typography-tiny font-bold text-destructive-600">
            {message}
        </div>
    );
};
