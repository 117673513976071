export { CentreCard } from "./CentreCard";
export { CentreCardSkeleton } from "./CentreCardSkeleton";
export { FilterIconsGroup } from "./FilterWithIcons";
export { SearchBarForm, SearchBarController } from "./SearchBar";
export {
    CategoryChip,
    DateTimeChip,
    MobileHeaderWithLocation,
} from "./SearchMobile";
export { NoResults, SearchResults } from "./SearchResults";
export { FilterDesktop, FilterDialog } from "./SearchFilter";

import {
    addHours,
    addMinutes,
    subMinutes,
    startOfDay,
    addDays,
    parse,
    isAfter,
    isEqual,
    isToday,
} from "date-fns";
import { format, fromZonedTime, toDate } from "date-fns-tz";
import { TFunction } from "i18next";
import { tzKL } from "lib/date-fns-util";
import { unique } from "utils/index";

export type FormQueryParams =
    | {
          locationId?: string | null;
          categoryId?: string | null;
          date?: string | null;
          time?: string | null;
          meridiem?: string | null;
          duration?: string | null;
          maxDistance?: string | null;
      }
    | undefined;

export type FormValues = {
    locationId?: string | null;
    categoryId?: string | null;
    dtrange?: DateTimeFormValue;
};

export type DateTimeFormValue = {
    date?: string | null;
    time?: string | null;
    meridiem?: string | null;
    duration?: string | null;
};

export type DateTimeHandlingProp = {
    startDt?: Date;
    endDt?: Date;
};

export const constructDateRange = (
    value?: DateTimeFormValue,
): DateTimeHandlingProp => {
    if (!value?.date) return { startDt: undefined, endDt: undefined };
    const meridiem = value.meridiem ?? "AM";
    const time = value.time ?? "12:00";
    const dateTime =
        time >= "13:00"
            ? parse(`${value.date} ${time}`, "yyyy-MM-dd kk:mm", new Date())
            : parse(
                  `${value.date} ${time}${meridiem}`,
                  "yyyy-MM-dd hh:mma",
                  new Date(),
              );
    const startDt = fromZonedTime(dateTime, tzKL);
    const endDt = addHours(startDt, parseFloat(value.duration ?? "0"));
    return { startDt, endDt };
};

export const dateInputOptions = (
    t: TFunction<["components/SearchPage", "common"]>,
    date?: string | null,
    time?: string | null,
): {
    timeOptions: { value: string; label: string }[];
    meridiemOptions: { value: string; label: string }[];
    durationOptions: { value: string; label: string }[];
} => {
    const options: Date[] = [];
    const startOfDate = startOfDay(
        date ? toDate(date) : addDays(new Date(), 1),
    );
    const optionsPivot = 7;
    const timeAllowance = subMinutes(new Date(), 20);
    for (let i = optionsPivot; i < 24; i++) {
        const t = addHours(startOfDate, i);
        if (t > timeAllowance) options.push(t);
        const t2 = addMinutes(t, 30);
        if (t2 > timeAllowance) options.push(t2);
    }
    for (let i = 0; i < optionsPivot; i++) {
        const t = addHours(startOfDate, i);
        if (t > timeAllowance) options.push(t);
        const t2 = addMinutes(t, 30);
        if (t2 > timeAllowance) options.push(t2);
    }
    let filterOptions = options;
    if (date && time) {
        const inputDate = parse(
            `${date} ${time}`,
            "yyyy-MM-dd kk:mm",
            new Date(),
        );
        if (isToday(inputDate)) {
            filterOptions = filterOptions.filter((o) => {
                if (isEqual(o, inputDate)) return true;
                return isAfter(o, inputDate);
            });
        }
    }
    const timeOptions = unique(
        options.map((o) => ({
            value: format(o, "kk:mm"),
            label: format(o, "hh:mm"),
        })),
    );

    const meridiemOptions = unique(
        filterOptions.map((o) => format(o, "a")),
    ).map((o) => ({
        value: o,
        label: t(`common:${o}`, o) as string,
    }));

    const durationOptions = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6].map(
        (o) => ({
            value: o.toString(),
            label: t("common:hour", "{{count}} Hour(s)", {
                count: o,
            }) as string,
        }),
    );
    return { timeOptions, meridiemOptions, durationOptions };
};
